<template>
  <div>
    <v-snackbar v-model="snakbarVisible"> {{ snakbarText }}</v-snackbar>

    <v-card>
      <v-row style="margin: 10px">
        <v-btn color="primary" @click="newUserDialog = !newUserDialog">Добавить пользователя</v-btn>

        <v-dialog height="auto" width="auto" v-model="newUserDialog">
          <v-card style="padding: 20px" elevation="11">
            <v-card-title>Добавление пользователя</v-card-title>
            <v-text-field variant="outlined"
                          label="Инициалы"
                          v-model="newUser.name">
            </v-text-field>
            <v-text-field variant="outlined"
                          label="Пароль"
                          v-model="newUser.password">
            </v-text-field>
            <v-row justify="center">
              <v-btn color="green" @click="saveNewUser()">Сохранить</v-btn>
            </v-row>
          </v-card>
        </v-dialog>

         <v-dialog height="auto" width="auto" v-model="editUserDialog">
          <v-card style="padding: 20px" elevation="11">
            <v-card-title>Редактирование</v-card-title>
            <v-text-field variant="outlined"
                          label="Инициалы"
                          v-model="newUser.name">
            </v-text-field>
            <v-text-field variant="outlined"
                          label="Пароль"
                          v-model="newUser.password">
            </v-text-field>
            <v-row justify="center">
              <v-btn color="green" @click="saveNewUser()">Сохранить</v-btn>
            </v-row>
          </v-card>
        </v-dialog>
      </v-row>
      <VDataTable :headers="headers" :items="users" style="margin: 10px">
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
              size="small"
              class="me-2"
              @click="editItem(item.raw)">
            mdi-pencil
          </v-icon>
          <v-icon
              size="small"
              @click="deleteItem(item.raw)">
            mdi-delete
          </v-icon>
        </template>
      </VDataTable>
    </v-card>
  </div>
</template>
<script>
import API from '../auth.js'



export default ({
  created() {
    this.getUsers()
  },
  data() {
    return {
      headers: [
        {title: 'Инициалы', key: 'name', align: 'center'},
        {title: '', key: 'actions'}],
      users: [],
      roles: [],
      api: process.env.VUE_APP_ENV_API,
      newUserDialog: false,
      newUser: {},
      snakbarVisible: false,
      snakbarText: "",
    }
  },
  methods: {
    getUsers() {
      API.
get(this.api + 'api/auth/user').then(response => {
        this.users = response.data.data
      });
    },
    saveNewUser() {
      this.newUser.role_id = "1";
      API.
post(this.api + 'api/auth/user', this.newUser).then(response => {
        this.users.push(response.data.data),
            this.snakbarVisible = true,
            this.snakbarText = "Пользователь добавлен",
            this.newUserDialog = false
      })
    },
    editItem(item) {
    },
    deleteItem(item) {
    }
  }
})
</script>


<style scoped>

</style>
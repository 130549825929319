<template>
  <div>
    <v-navigation-drawer
        v-model="drawer"
        :rail="rail"
        permanent
        @click="rail = false"
    >
      <v-list-item
          style="margin-left: 10px; font-size: 15px"
          prepend-icon="mdi-home-city"
          title="Меню"
          nav
      >
        <template v-slot:append>
          <v-btn
              variant="text"
              icon="mdi-chevron-left"
              @click.stop="rail = !rail"
          ></v-btn>
        </template>
      </v-list-item>

      <v-divider></v-divider>

      <v-list variant="plain" density="compact" color="primary"
              nav>
        <v-list-item prepend-icon="mdi-numeric-1-box-multiple-outline" title="Дневной план" to="/"
                     value="day"></v-list-item>
        <v-list-item to="/month" prepend-icon="mdi-numeric-2-box-multiple-outline" title="Месячный план"
                     value="month"></v-list-item>
        <v-list-item to="/years" prepend-icon="mdi-numeric-3-box-multiple-outline" title="Годовой план"
                     value="year"></v-list-item>
        <v-divider/>
        <v-list-item to="/responsibles" prepend-icon="mdi-numeric-4-box-multiple-outline" title="Список преподавателей"
                     value="responsible"></v-list-item>
        <v-divider/>

        <v-list-item v-if="false !== false"
                     @click="dialogPrint = true" value="print" prepend-icon="mdi-file-excel"
                     title="Сформировать выгрузку">
          <v-dialog max-width="500px" v-model="dialogPrint">
            <v-card>
              <v-autocomplete style="margin-left: 1.8rem" variant="solo-filled"
                              color=primary
                              item-title="month"
                              item-value="id" label="Месяц" :items="months" v-model="currentMonth">

              </v-autocomplete>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    variant="text"
                    @click="dialogPrint = false"
                >
                  Закрыть
                </v-btn>
                <v-btn
                    color="primary"
                    variant="text"
                    @click="getExcel()"
                >
                  Сформировать
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn @click="exit()" v-if="visibleAuth && !rail " color="primary" block>
            Выход
          </v-btn>
          <v-btn @click="exit()" v-if="!visibleAuth && !rail " color="primary" block>
            Вход
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </div>

</template>

<script>
import API from '../auth.js'

export default {
  data() {
    return {
      visibleAuth: null,
      drawer: true,
      dialogPrint: false,
      rail: false,
      months: [{id: 9, month: 'сентябрь'},
        {id: 10, month: 'октябрь'},
        {id: 11, month: 'ноябрь'},
        {id: 12, month: 'декабрь'},
        {id: 1, month: 'январь'},
        {id: 2, month: 'февраль'},
        {id: 3, month: 'март'},
        {id: 4, month: 'апрель'},
        {id: 5, month: 'май'},
        {id: 6, month: 'июнь'},
      ],
      api: process.env.VUE_APP_ENV_API,
      currentMonth: null,
    }
  },
  watcher: {
    '$route': 'authorization'

  },
  created() {
    this.authorization();
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Мероприятия';
      }
    },
  },
  methods: {
    exit() {
      localStorage.removeItem('access_token');
      this.$router.push('authorization');
    },
    getExcel() {
      // let formattedDate = new Date().getDate() + "-" + this.currentMonth + "-" +  new Date().getFullYear().toString().split("-").reverse().join("");
      API.post(this.api + 'api/auth/report', {month: this.currentMonth}).then()
    },
    authorization() {
      if (localStorage.getItem('access_token') == null) {
        this.visibleAuth = false;
      } else {
        this.visibleAuth = true;
      }
    }
  },

};
</script>

<style>

</style>
<template>

  <v-dialog
      v-model="visibleWindowMonthDialog"
      width="1024">
    <v-card>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
                cols="12"
                sm="6"
                md="6"
                style="margin-bottom: 0px"
            >
              <v-text-field
                  variant="outlined"
                  label="Ответственный"
                  v-model="visibleMonthEvent.responsible"
                  style="padding-bottom: 0px"
                  readonly>
              </v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="6"
                style="padding-bottom: 0px"
            >
              <v-text-field
                  variant="outlined"
                  label="Площадка"
                  v-model="visibleMonthEvent.place.name"
                  style="margin-bottom: 0px"
                  readonly>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-row style="margin: 0px; padding-top: 0px">
                <v-text-field
                    variant="outlined"
                    label="Мероприятие"
                    v-model="visibleMonthEvent.name"
                    readonly>
                </v-text-field>
              </v-row>
            </v-col>
            <v-col
                cols="12"
                sm="6"
            >
              <v-text-field variant="outlined"
                            type="date"
                            label="Дата начала"
                            v-model="visibleMonthEvent.startDate"
                            readonly>
              </v-text-field>

            </v-col>
            <v-col
                cols="12"
                sm="6"
            >
              <v-text-field
                  readonly
                  variant="outlined"
                  type="date"
                  label="Дата окончания"
                  v-model="visibleMonthEvent.endDate">
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            variant="text"
            @click="visibleWindowMonthDialog = false"
        >
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog
      v-model="visibleWindowDialog"
      width="1024">
    <v-card>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
                cols="12"
                sm="6"
                md="6"
                style="margin-bottom: 0px"
            >
              <v-text-field
                  variant="outlined"
                  label="Ответственный"
                  v-model="visibleEvent.responsible"
                  style="padding-bottom: 0px"
                  readonly>
              </v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="6"
                style="padding-bottom: 0px"
            >
              <v-text-field
                  variant="outlined"
                  label="Площадка"
                  v-model="visibleEvent.place.name"
                  style="margin-bottom: 0px"
                  readonly>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-row style="margin: 0px; padding-top: 0px">
                <v-text-field
                    variant="outlined"
                    label="Мероприятие"
                    v-model="visibleEvent.name"
                    readonly>
                </v-text-field>
              </v-row>
            </v-col>
            <v-col
                cols="12"
                sm="6"
            >
              <v-text-field variant="outlined"
                            type="date"
                            label="Дата начала"
                            v-model="visibleEvent.startDate"
                            readonly>
              </v-text-field>

            </v-col>
            <v-col
                cols="12"
                sm="6"
            >
              <v-text-field
                  readonly
                  variant="outlined"
                  type="date"
                  label="Дата окончания"
                  v-model="visibleEvent.endDate">
              </v-text-field>
            </v-col>
          </v-row>
          <v-data-table
          :headers="docsHeaders"
          :items="visibleEventDocs">
          <template v-slot:[`item.name`]="{ item }" v-model="visibleEvent.docs">
            <a :href="getDocUrl(item.name)" target="_blank">
              {{ item.name }}
            </a>
          </template>
        </v-data-table> 
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            variant="text"
            @click="visibleWindowDialog = false"
        >
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-container>
    <v-row>
      <v-col xs="4" sm="12" md="4" xl="4">
        <v-card>
          <v-toolbar color="primary" title="Конкурсные"></v-toolbar>
          <v-list>
            <v-list-item v-for="(event, i) in events"
            @click="visibleWindowDialog = !visibleWindowDialog,console.log(event), visibleEvent = event">
              <p style="word-wrap: break-word">{{ event.name }}</p>
              <v-list-item-subtitle>
                <p style="text-wrap: balance;">
                  <v-icon>mdi-account</v-icon>
                  {{ event.responsible }}
                </p>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <p>
                  <v-icon>mdi-home</v-icon>
                  {{ event.place.name }}
                </p>
              </v-list-item-subtitle>
              <v-divider
                  :thickness="1"
                  class="border-opacity-50"
                  color="primary"
              ></v-divider>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>

      <v-col xs="4" sm="12" md="4" xl="4">
        <v-card>
          <v-toolbar color="primary" title="Методические"></v-toolbar>
          <v-list>
            <v-list-item v-for="(event, i) in eventsMethod"
             @click="visibleWindowDialog = !visibleWindowDialog,console.log(event), visibleEvent = event">
              <p style="word-wrap: break-word">{{ event.name }}</p>
              <v-list-item-subtitle>
                <p style="text-wrap: balance;">
                  <v-icon>mdi-account</v-icon>
                  {{ event.responsible }}
                </p>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <p>
                  <v-icon>mdi-home</v-icon>
                  {{ event.place.name }}
                </p>
              </v-list-item-subtitle>
              <v-divider
                  :thickness="1"
                  class="border-opacity-50"
                  color="primary"
              ></v-divider>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>

      <v-col xs="4" sm="12" md="4" xl="4">
        <v-card>
          <v-toolbar color="primary" title="Воспитательные"></v-toolbar>
          <v-list>
            <v-list-item v-for="(event, i) in eventsEducational"
            @click="visibleWindowDialog = !visibleWindowDialog,console.log(event), visibleEvent = event">
              <p style="word-wrap: break-word">{{ event.name }}</p>
              <v-list-item-subtitle>
                <p style="text-wrap: balance;">
                  <v-icon>mdi-account</v-icon>
                  {{ event.responsible }}
                </p>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <p>
                  <v-icon>mdi-home</v-icon>
                  {{ event.place.name }}
                </p>
              </v-list-item-subtitle>
              <v-divider
                  :thickness="1"
                  class="border-opacity-50"
                  color="primary"
              ></v-divider>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API from '../auth.js'

export default {
  watch: {
    visibleEvent() {
      this.getEditingDocs();
    }
  },
  data() {
    return {
      visibleWindowDialog: false,
      visibleWindowMonthDialog: false,
      visibleMonthEvent: {},
      visibleEvent: {},
      visibleEventDocs: {},
      tab: null,
      api: process.env.VUE_APP_ENV_API,
      minio: process.env.VUE_APP_ENV_MINIO,
      events: [],
      eventsMethod: [],
      eventsEducational: [],
      docsHeaders:[
        {title: '', key:'name', sortable: false, align: 'center'},
      ],
    }
  },
  created() {
    this.getEvents();
  },
  methods: {
    getEvents() {
      API.get(this.api + 'api/events/today').then(response => {
        response.data.message.forEach(event => {
          if (event.type === "Конкурсные" && event.status.name === 'assigned') {
            this.events.push(event);
          } else if (event.type === "Методические" && event.status.name === 'assigned') {
            this.eventsMethod.push(event);
          } else if (event.type === "Воспитательные" && event.status.name === 'assigned'){
            this.eventsEducational.push(event);
          }
        });
      })
    },
    getEditingDocs() {
      this.visibleEventDocs = Object.values(this.visibleEvent.docs || {});
    },
    getDocUrl(fileName) {
      const baseUrl = this.minio;
      return `${baseUrl}${fileName}`;
    }
  },
}
</script>

// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Import Vuetify components
import { createVuetify } from 'vuetify'
import { VDataTable } from 'vuetify/components'
import {ru} from 'vuetify/locale'

export default createVuetify({
  components: {
    VDataTable,
  },
    locale: {
      locale: 'ru',
      messages: { ru },
    },
  })

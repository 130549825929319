<template>
  <div style="background-color: #eeeeee">
    <v-layout>
      <HeadersView :key="$route"/>
      <v-main style="height: 250px">
        <v-col>
          <router-view/>
        </v-col>
      </v-main>
    </v-layout>
  </div>
</template>
<script>
import HeadersView from "@/components/HeadersView.vue";

export default ({
  components: {
    HeadersView
  },
  created() {
    this.authorization();
  },
  methods: {
    exit() {
      localStorage.removeItem('access_token');
      this.$router.push('authorization');
      this.visibleAuth = false;
    },
    authorization() {
      if (localStorage.getItem('access_token') == null) {
        this.visibleAuth = false;
      } else {
        this.visibleAuth = true;
      }
    }
  },
  data() {
    return {
      visibleAuth: null,
      drawer: true,
      rail: false,
    }
  },
  watcher: {
    '$route': 'authorization'

  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Мероприятия';
      }
    },
  }
})

</script>
<style>

</style>

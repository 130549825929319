<template>
  <div>
    <v-snackbar v-model="errorAuthSnackbar">
      {{ textErrorSnackbar }}
      <template v-slot:actions>
        <v-btn color="primary" variant="text" @click="errorAuthSnackbar = false">
          Закрыть
        </v-btn>
      </template>
    </v-snackbar>
    <v-card style="padding: 20px; margin: 5% 30%" elevation="9">
      <v-col style="justify-content: space-around; justify-content: center">
        <v-row>
          <v-text-field variant="outlined" v-model="login" label="Логин"></v-text-field>
        </v-row>
        <v-row>
          <v-text-field type="password" v-model="password" variant="outlined" label="Пароль"></v-text-field>
        </v-row>
        <v-row style="justify-content: center; margin-top: 5px">
          <v-btn @click="authorization()" style="margin: 10px;" color="primary" outlined>Вход</v-btn>
        </v-row>
       </v-col>
    </v-card>
  </div>
</template>

<script>
import axios from "axios"

export default ({
  data() {
    return {
      login: null,
      password: null,
      api: process.env.VUE_APP_ENV_API,
      textErrorSnackbar: null,
      errorAuthSnackbar: false,
    }
  },
  methods: {
    authorization() {
      axios.post(this.api + 'api/auth/login', {email: this.login, password: this.password}).then(res => {
        localStorage.setItem('access_token', res.data.access_token);
                this.$router.push('/day');
      }).catch(error => {
        if (error.response) {
          this.textErrorSnackbar = "Неверный логин или пароль",
          this.errorAuthSnackbar = true
        }
      })
    },
  }
})
</script>